import { useEffect, useState } from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import SearchIcon from '../../assets/icons/search.svg?react';
import Select from '../ui/Select';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import {
  DerivedOrderDeliveryStatusLabels,
  OrderLicenseStatusLabels,
  Option,
  DerivedOrderDeliveryStatus
} from '../../common/types';

export default function OrdersSearch({
  onSearch,
  search,
  orderGroupOptions,
  orderGroupId,
  deliveryStatus,
  licenseStatus
}: {
  onSearch: (values: Record<string, string | any>) => void;
  search?: string;
  orderGroupOptions: Option[];
  orderGroupId?: string;
  deliveryStatus?: string;
  licenseStatus?: string;
}) {
  const [query, setQuery] = useState<string | undefined>();

  interface FormProps {
    search: string;
    orderGroupId: string;
    licenseStatus: string;
    deliveryStatus: string;
  }

  const { register, handleSubmit, formState, control, setValue } = useForm<FormProps>({
    defaultValues: {
      search: '',
      orderGroupId: '',
      licenseStatus: '',
      deliveryStatus: ''
    }
  });

  useEffect(() => {
    if (search) {
      setValue('search', search);
    }

    if (orderGroupId) {
      setValue('orderGroupId', orderGroupId);
    }

    if (licenseStatus) {
      setValue('licenseStatus', licenseStatus);
    }

    if (deliveryStatus) {
      setValue('deliveryStatus', deliveryStatus);
    }
  }, [search, orderGroupId, licenseStatus, deliveryStatus]);

  useEffect(() => setQuery(search), [search]);

  const onSubmit: SubmitHandler<FormProps> = (values) => {
    onSearch && onSearch(values);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit);
    }
  };

  const orderDeliveryStatuses = Object.keys(DerivedOrderDeliveryStatusLabels).map((key) => ({
    value: key,
    label: DerivedOrderDeliveryStatusLabels[key as DerivedOrderDeliveryStatus]
  }));

  const orderLicenseStatuses = Object.keys(OrderLicenseStatusLabels).map((key: string) => ({
    value: key,
    label: OrderLicenseStatusLabels[key]
  }));

  return (
    <div>
      <search className="container mx-auto flex flex-col items-stretch gap-8">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="rounded-lg bg-primary-200 gap-2 p-3 border border-primary-300">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <Input
                rounded
                className="grow"
                defaultValue={search}
                prefixNode={<SearchIcon className="stroke-gray-400" />}
                placeholder="Search by Title or PO Nr."
                error={formState.errors.search?.message}
                onKeyDown={handleKeyDown}
                {...register('search')}
              />
              <Button rounded variant="primary" type="submit">
                Search
              </Button>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <Controller
                name="orderGroupId"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      className="col-span-2"
                      options={orderGroupOptions}
                      isMulti={false}
                      isClearable={true}
                      placeholder="Order Group"
                      onChange={(option: any) => {
                        option ? field.onChange(option.value) : field.onChange('');
                        handleSubmit(onSubmit)();
                      }}
                      value={orderGroupOptions.find((option) => option.value === field.value)}
                    />
                  </>
                )}
              />
              <Controller
                name="licenseStatus"
                control={control}
                render={({ field }) => (
                  <Select
                    className="col-span-2"
                    options={orderLicenseStatuses}
                    isMulti={false}
                    isClearable={true}
                    placeholder="License Status"
                    onChange={(option: any) => {
                      option ? field.onChange(option.value) : field.onChange('');
                      handleSubmit(onSubmit)();
                    }}
                    value={orderLicenseStatuses.find((option) => field.value === option.value)}
                  />
                )}
              />

              <Controller
                name="deliveryStatus"
                control={control}
                render={({ field }) => (
                  <Select
                    className="col-span-2"
                    options={orderDeliveryStatuses}
                    isMulti={false}
                    isClearable={true}
                    placeholder="Delivery Status"
                    onChange={(option: any) => {
                      option ? field.onChange(option.value) : field.onChange('');
                      handleSubmit(onSubmit)();
                    }}
                    value={orderDeliveryStatuses.find((option) => field.value === option.value)}
                  />
                )}
              />
            </div>
          </div>
        </form>
      </search>
    </div>
  );
}
