import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { cva, type VariantProps } from 'class-variance-authority';

/*
  Examples how to use it.
  <Alert variant="danger" rounded>
    <DeleteIcon className="w-8 h-8" />
    <div>
        <h3>We’ve just released a new feature</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum dolor.</p>
    </div>
  </Alert>
*/
const alertStyle = cva(['flex flex-row gap-2 justify-center transition text-sm'], {
  variants: {
    variant: {
      default: ['text-gray-700 bg-white border border-gray-300 shadow-btn p-4'],
      danger: ['text-danger-700 bg-danger-25 border border-danger-300 shadow-btn p-4'],
      warning: ['text-warning-700 bg-warning-25 border border-warning-300 shadow-btn p-4'],
      success: ['text-success-700 bg-success-25 border border-success-300 shadow-btn p-4'],
      info: [
        'text-primary-700 bg-primary-25 border border-primary-300 bg-opacity-30 shadow-btn p-4'
      ]
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

export interface AlertProps
  extends React.HTMLProps<HTMLDivElement>,
    VariantProps<typeof alertStyle> {
  rounded?: boolean;
}

const Alert: React.FC<AlertProps> = ({ className, variant, rounded, ...props }) => {
  const alertClasses = clsx(
    {
      'rounded-lg': rounded
    },
    className
  );
  return <div className={twMerge(alertStyle({ variant, className: alertClasses }))} {...props} />;
};

export default Alert;
