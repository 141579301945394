import React, { ReactNode } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import { ContentPortalFile, AnalyzeFile } from '../../features/ingestion/types';
import RequireUserGroups from '../../common/providers/RequireUserGroups';
import { useFetchProcessDetailsQuery } from '../../features/ingestion/ingestionApi';
import StudioLogo from '../../components/shared/StudioLogo';
import { RestoreStatusTag, MoveStatusTag } from '../../components/ingestion/FileStatusTags';
import { useAppDispatch } from '../../app/hooks';
import {
  setDetectedFiles,
  setEditVersion,
  setPaths,
  setSelectedCandidate,
  setSourceCatalog,
  setStudio,
  setTitleCandidates
} from '../../features/ingestion/ingestSlice';
import { VUE_APP_HOST } from '../../common/constants';
import { formatReferentType } from '../../components/shared/Formatting';
import Button from '../../components/ui/Button';
import CopyIcon from '../../assets/icons/copy.svg?react';
import FileCheckIcon from '../../assets/icons/file-check.svg?react';
import UploadCloudIcon from '../../assets/icons/upload-cloud.svg?react';
import HomeIcon from '../../assets/icons/home.svg?react';
import Skeleton from '../../components/ui/Skeleton';
import { ProcessStatus } from '../../components/ingestion/IngestionStatus';
import { languages } from '../../common/languages';
import { Helmet } from 'react-helmet-async';

const breadcrumbItems: BreadcrumbsItem[] = [
  {
    link: '/',
    icon: <HomeIcon />
  },
  {
    title: 'Ingestion',
    link: '/ingestion'
  },
  {
    title: 'Process'
  }
];

const ListItem = ({
  primary,
  secondary
}: {
  primary: string;
  secondary?: string | number | ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <h6 className="text-sm font-medium text-gray-900">{primary}</h6>
      <div className="text-sm text-gray">{secondary}</div>
    </div>
  );
};

export default function ProcessDetailsPage() {
  const params = useParams();
  const { data: process, isFetching } = useFetchProcessDetailsQuery(params.processId || '', {
    refetchOnMountOrArgChange: true
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function copyToNewIngestion() {
    if (process) {
      const analyzeFiles = process.files.map((file) => {
        const analyzeFile: AnalyzeFile = {
          location: file.location,
          language: file.language,
          fileName: file.location.substring(file.location.lastIndexOf('/') + 1),
          included: true
        };
        return analyzeFile;
      });
      dispatch(setPaths(process.userInput));
      dispatch(setDetectedFiles(analyzeFiles));

      dispatch(setTitleCandidates([process]));
      dispatch(setSelectedCandidate(process));
      if (process.studio) {
        dispatch(setStudio(process.studio));
      }
      if (process.editVersion) {
        dispatch(setEditVersion(process.editVersion));
      }
      dispatch(setSourceCatalog(process.sourceCatalog));
      navigate('/ingestion/new/confirmation');
    } else {
      console.error('Could not copy to new ingestion');
    }
  }

  const QCLink = (props: { catalogId: number; editAboveId: number }) => (
    <Link to={`${VUE_APP_HOST}/catalog/${props.catalogId}/quality-control/${props.editAboveId}`}>
      <Button rounded variant="primaryOutlined" className="flex flex-row gap-1">
        <FileCheckIcon className="stroke-primary w-5 h-5" /> Go to quality control
      </Button>
    </Link>
  );

  return (
    <>
      <Helmet>
        <title>Above Media | Ingestion Details</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8">
        <div className="p-5 flex flex-row gap-4 items-center justify-center">
          <div className="grow gap-2 flex flex-row items-center font-semibold text-lg text-gray-900">
            Ingestion process result
          </div>
          <RequireUserGroups groups={['above']}>
            <div className="flex-none flex flex-row gap-4 items-center">
              {process?.isEpisode && process?.seriesAboveId && process?.editAboveId && (
                <QCLink catalogId={process.seriesAboveId} editAboveId={process.editAboveId} />
              )}
              {process?.isEpisode === false && process?.titleAboveId && process?.editAboveId && (
                <QCLink catalogId={process.titleAboveId} editAboveId={process.editAboveId} />
              )}
              <Button
                onClick={copyToNewIngestion}
                rounded
                variant="primary"
                className="flex flex-row gap-1">
                <CopyIcon className="stroke-white w-5 h-5" /> Copy to new ingestion
              </Button>
              {/* <Button
                rounded
                className="px-1 py-3.5 bg-transparent border-0 focus:shadow-none active:shadow-none">
                <VDotsIcon />
              </Button> */}
            </div>
          </RequireUserGroups>
        </div>
        <div className="relative">
          {isFetching && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-10"></div>
              <div className="border-t border-gray-100">
                <Skeleton />
              </div>
            </>
          )}
          {!isFetching && (
            <>
              <div className="grid grid-cols-3 gap-4 p-8 border-t border-gray-100">
                <div className="flex flex-col gap-4">
                  <ListItem primary="Process ID" secondary={process?.id} />
                  <ListItem primary="WBTVD title data MPM number" secondary={process?.mpmNumber} />
                  <ListItem primary="EIDR" secondary={process?.eidrId} />
                </div>
                <div className="flex flex-col gap-4">
                  <ListItem
                    primary="Created"
                    secondary={new Date(process?.createdAt || '').toLocaleString()}
                  />
                  <ListItem primary="User" secondary={process?.user.username} />
                  {process?.processStatus && (
                    <ListItem
                      primary="Process status"
                      secondary={<ProcessStatus status={process.processStatus} />}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-4 items-start">
                  <ListItem
                    primary="Modified"
                    secondary={new Date(process?.modifiedAt || '').toLocaleString()}
                  />
                  <ListItem primary="Studio" />
                  <StudioLogo studio={process?.studio || ''} />
                </div>
              </div>
              <div className="flex flex-row w-full gap-4 p-8 border-t border-gray-100">
                {process?.isEpisode && (
                  <div className="flex flex-col gap-4 flex-1">
                    <h5 className="text-gray-900 font-bold">Series</h5>
                    <ListItem primary="Id" secondary={process?.seriesAboveId} />
                    <ListItem primary="Name" secondary={process?.seriesName} />
                    <ListItem primary="EIDR" secondary={process?.seriesEidrId} />
                  </div>
                )}
                {process?.titleName && (
                  <div className="flex flex-col gap-4 flex-1">
                    <h5 className="text-gray-900 font-bold">
                      {process?.isEpisode ? 'Episode' : formatReferentType(process.referentType)}
                    </h5>
                    <ListItem primary="Id" secondary={process.titleAboveId} />
                    <ListItem primary="Name" secondary={process.titleName} />
                    <ListItem primary="EIDR" secondary={process.eidrId} />
                    <ListItem primary="Studio" secondary={process.studio} />
                    <ListItem primary="Edit version" secondary={process.editVersion} />
                    <ListItem primary="Referent type" secondary={process.referentType} />
                    {process.isEpisode && [
                      <ListItem
                        primary="Season number"
                        key="season_number"
                        secondary={process.seasonNumber}
                      />,
                      <ListItem
                        primary="Episode number"
                        key="episode_number"
                        secondary={process.episodeNumber}
                      />
                    ]}
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full gap-4 p-8 border-t border-gray-100">
                <h5 className="text-gray-900 font-bold">Files</h5>
                <div className="flex flex-col gap-16 p-4">
                  {process?.files &&
                    process.files.map((file) => <FileProcess key={file.location} file={file} />)}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

interface FileProcessProps {
  file: ContentPortalFile;
}

function FileProcess({ file }: FileProcessProps) {
  const getAudioLanguageName = (code: string) =>
    languages.find((l) => l.value === code)?.label || code;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4 items-center">
        <div className="w-8">
          <UploadCloudIcon className="w-8 h-8 stroke-gray-400" />
        </div>
        <ListItem primary="Location" secondary={file.location || '—'} />
      </div>

      <div className="flex flex-row gap-4">
        <div className="w-8"></div>
        <div className="flex flex-col gap-4">
          {file.targetLocation && (
            <ListItem primary="Target location" secondary={file.targetLocation} />
          )}
          {!file.targetLocation && <ListItem primary="Target location" secondary={'—'} />}
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center">
        <div className="w-8"></div>
        <div className="grid grid-cols-2 gap-8">
          <ListItem primary="Move job ID" secondary={file.moveJobId || '—'} />
          <ListItem
            primary="Move status"
            secondary={
              <div className="inline-block">
                <MoveStatusTag file={file} />
              </div>
            }
          />
        </div>
      </div>
      {(file?.archived || file?.restoreStatus == 'SUCCESS') && (
        <div className="flex flex-row gap-4 items-center">
          <div className="w-8"></div>
          <div className="grid grid-cols-2 gap-8">
            <ListItem primary="Restore job ID" secondary={file.restoreJobId || '—'} />
            <ListItem
              primary="Restore status"
              secondary={
                <div className="inline-block">
                  <RestoreStatusTag file={file} />
                </div>
              }
            />
          </div>
        </div>
      )}
      {file.language && (
        <div className="flex flex-row gap-4 items-center">
          <div className="w-8"></div>
          <ListItem primary="Language" secondary={getAudioLanguageName(file.language)} />
        </div>
      )}
    </div>
  );
}
