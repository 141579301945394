import React from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import SearchIcon from '../../assets/icons/search.svg?react';

export default function IngestionSearch({
  onSearch,
  query,
  setQuery
}: {
  onSearch: (query?: string) => void;
  query?: string;
  setQuery?: (query?: string) => void;
}) {
  const handleSearch = () => {
    onSearch && onSearch(query);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      <div className="container mx-auto flex flex-col items-stretch gap-8">
        <div className="flex flex-row rounded-lg bg-primary-200 gap-2 px-4 py-3 border border-primary-300">
          <Input
            rounded
            className="grow"
            defaultValue={query}
            prefixNode={<SearchIcon className="stroke-gray-400" />}
            placeholder="Search title name or EIDR"
            onChange={(e) => setQuery && setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button rounded variant="primary" onClick={() => handleSearch()}>
            Search
          </Button>
        </div>
      </div>
    </div>
  );
}
