import Header from '../../components/shared/Header';
import { capitalize, keyBy } from 'lodash';
import toast from 'react-hot-toast';
import Select from '../../components/ui/Select';
import Button from '../../components/ui/Button';
import FormItem from '../../components/ui/FormItem';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import Input from '../../components/ui/Input';
import Switch from '../../components/ui/Switch';
import DeleteIcon from '../../assets/icons/delete.svg?react';
import HomeIcon from '../../assets/icons/home.svg?react';
import CartIcon from '../../assets/icons/cart.svg?react';
import VDotsIcon from '../../assets/icons/v-dots.svg?react';
import CalendarIcon from '../../assets/icons/calendar.svg?react';
import { useEffect, useRef, useState } from 'react';
import { Edit, SourceCatalog, Title } from '../../features/catalog/types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getCatalogItems } from '../../features/catalog/catalogApi';
import { OrderFormProps, Option, SourceLabels } from '../../common/types';
import {
  EncodeSystem,
  Organization,
  OrganizationAirline,
  SystemOption
} from '../../features/organizations/types';
import { Controller, FieldError, useFieldArray, useForm, useWatch, Control } from 'react-hook-form';
import { useLazyFetchTitleCardsQuery } from '../../features/titleCard/titleCardApi';
import useLocalStorage from '../../common/hooks/useLocalStorage';
import AddCycleFormModal from '../../components/order-creation/AddCycleFormModal';
import EditIcon from '../../assets/icons/edit.svg?react';
import { Popover, PopoverDropdownContent, PopoverTrigger } from '../../components/ui/Popover';
import Loader from '../../components/ui/Loader';
import { useUserContext } from '../../common/providers/UserProvider';
import { getRefreshToken } from '../../common/auth';
import { Helmet } from 'react-helmet-async';
import { OrderGroup } from '../../features/orders/types';
import { createOrder, getOrderGroups } from '../../features/orders/ordersApi';
import { RequestQueue } from '../../common/request_queue';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../app/store';
import {
  setOrderAssetMode,
  setTitleCards,
  setTitles,
  setOrderGroups,
  deleteAsset,
  setSelectedTitles,
  setSelectedRows,
  setSelectedAsset,
  setSelectedSystem,
  setSystems,
  resetAssets,
  setCurrentSection
} from '../../features/orders/orderSlice';
import AssetsView from '../../components/order-creation/AssetsView';
import Tag from '../../components/ui/Tag';
import { TitleLabel } from '../../components/shared/TitleLabel';
import { brandOptions } from '../../common/brands';
import {
  addYears,
  eachMonthOfInterval,
  endOfMonth,
  format,
  getDate,
  setDate,
  startOfMonth,
  startOfToday
} from 'date-fns';

export interface SystemItem {
  id?: number;
  title: string;
  included?: boolean;
}

const requestQueue = new RequestQueue();

const ActionSet = ({ item, id }: { item: Title; id: string }) => {
  const { assets } = useSelector((state: RootState) => state.order);

  const dispatch = useDispatch();
  const onAssetDelete = () => dispatch(deleteAsset({ item, id }));
  const onEdit = () => {
    dispatch(setSelectedAsset(assets?.[item._id]));
    dispatch(setSelectedSystem(id));
    dispatch(setSelectedTitles([item]));
    dispatch(setSelectedRows(new Set([item._id])));
    dispatch(setCurrentSection('configure-order-assets'));
    dispatch(setOrderAssetMode('edit'));
    if (assets?.[item._id].assetSystems[id].assetsType === 'mezzanine') {
      dispatch(
        setSystems([
          {
            value: 'mezzanine',
            label: 'Mezzanine',
            ifeSystem: 'Mezzanine',
            ifeFormat: 'above_mezzanine_default',
            poDestination: '',
            vtkTemplate: '',
            id: 1,
            filename: ''
          }
        ])
      );
    } else {
      dispatch(
        setSystems(
          assets?.[item._id].assetSystems[id].systems.filter((system) => system.value === id)
        )
      );
    }
  };
  return (
    <div className="flex flex-row gap-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="default" link rounded>
            <VDotsIcon className="stroke-gray-900 w-6 h-6" />
          </Button>
        </PopoverTrigger>
        <PopoverDropdownContent align="end">
          <div className="divide-y divide-gray-100 min-w-max flex flex-col justify-start">
            <Button
              variant="default"
              size="sm"
              onClick={() => onEdit()}
              block
              className="border-0 bg-white justify-start outline-none">
              <EditIcon className="w-5 h-5 stroke-gray-500" /> Edit
            </Button>
            <Button
              variant="default"
              size="sm"
              block
              type="button"
              onClick={() => onAssetDelete()}
              className="border-0 bg-white justify-start outline-none">
              <DeleteIcon className="w-5 h-5 stroke-danger" /> Delete
            </Button>
          </div>
        </PopoverDropdownContent>
      </Popover>
    </div>
  );
};

export default function OrderPage() {
  const { titles, orderAssetMode, orderGroups, assets } = useSelector(
    (state: RootState) => state.order
  );
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [fetchTitleCards] = useLazyFetchTitleCardsQuery();
  const { userOrgs } = useUserContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const source = searchParams.get('source');
  const [savedOrg, setSavedOrg] = useLocalStorage('org', '');
  const [bulkFill, setBulkFill] = useState(true);

  const [selectedOrg, setSelectedOrg] = useState<Organization>(userOrgs[0]);

  let refreshToken: string;
  (async () => await getRefreshToken())().then((results) => {
    refreshToken = results;
  });
  const orgOptions: Option[] = userOrgs.map((org: Organization) => ({
    value: org.orgId,
    label:
      source && org.sourceCatalogs.indexOf(source) === -1 ? (
        <span
          title={`You cannot select this organization! it is not permitted for ${SourceLabels[source]} catalog`}>
          {org.name}
        </span>
      ) : (
        org.name
      ),
    disabled: source && org.sourceCatalogs.indexOf(source) === -1
  }));

  const orderGroupOptions: Option[] = orderGroups.map((orderGroup: OrderGroup) => ({
    value: orderGroup._id,
    label: `${orderGroup.title} - ${orderGroup.clientText}`
  }));

  const ids = params.ids;

  const { register, handleSubmit, control, formState, setValue, reset, getValues } = useForm<any>({
    defaultValues: {
      organization: selectedOrg?.orgId,
      externalId: '',
      includeVideo: true,
      requireHd: false,
      require16x9: false,
      editVersion: '',
      orderType: '',
      titleCard: '',
      subtitleLanguages: [],
      audioLanguages: [],
      orderGroupId: '',
      titles: [],
      dynamicFields: []
    }
  });

  const firstStartDateField = useWatch({ name: 'dynamicFields.0.start_date', control });
  const firstEndDateField = useWatch({ name: 'dynamicFields.0.end_date', control });
  const firstDealRateField = useWatch({ name: 'dynamicFields.0.rate', control });
  const orderGroupId = useWatch({ name: 'orderGroupId', control });
  const organization = useWatch({ name: 'organization', control });

  const selectedAirline = orderGroups.find((group) => group._id === orderGroupId);

  const systemsOptions: SystemOption[] = (
    selectedOrg?.airlines.find(
      (item: OrganizationAirline) => item.iataCode === selectedAirline?.clientId
    )?.encodeSystems as EncodeSystem[]
  )?.map((item, index) => ({
    value: `${item.vtkTemplate}-${item.poDestination}`,
    label: item.ifeSystem,
    ifeSystem: item.ifeSystem,
    ifeFormat: item.ifeFormat,
    poDestination: item.poDestination,
    vtkTemplate: item.vtkTemplate,
    id: index,
    filename: ''
  }));

  const systemsMap: { [key: string]: SystemOption } = keyBy(systemsOptions, 'value');

  useFieldArray({
    control,
    name: 'dynamicFields'
  });

  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Catalog',
      link: '/catalog'
    },
    {
      title: 'Order Details'
    }
  ];

  const populateOrderGroups = () => {
    if (selectedOrg) {
      requestQueue.addRequest(() => {
        return getOrderGroups(selectedOrg.orgId, (result: OrderGroup[]) => {
          dispatch(setOrderGroups(result));
          return new Promise((resolve) => setTimeout(resolve));
        });
      });
    } else {
      dispatch(setOrderGroups([]));
    }
  };

  useEffect(() => {
    if (savedOrg) {
      const org = userOrgs.find((org: Organization) => org.orgId === savedOrg);
      setSelectedOrg(org || userOrgs[0]);
    }
  }, [savedOrg]);

  useEffect(() => {
    if (selectedOrg) {
      setValue('organization', selectedOrg.orgId);
      populateOrderGroups();
      setValue('orderType', selectedOrg?.orderTypes[0]);
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (titles) {
      setLoading(false);
    }
  }, [titles]);

  useEffect(() => {
    if (ids && source) {
      setLoading(true);
      getCatalogItems(ids.split('-'), source as SourceCatalog, (titles: Title[] | null) => {
        if (titles) {
          dispatch(setTitles(titles));
        }
      });
      fetchTitleCards(true).then((response) => {
        if (response.data) {
          dispatch(setTitleCards(response.data.Items));
        }
      });
    }
  }, [ids, source]);

  const onOrderSubmit = (values: OrderFormProps) => {
    const payload: any = {};
    payload.organization = selectedOrg?.orgId;
    payload.sourceCatalog = source ? source : 'ABOVE';
    payload.orderGroupId = values.orderGroupId;
    payload.brand = values.brand;
    payload.externalId = values.externalId;

    const titlesPayload: any = [];
    titles.map((title: Title, index) => {
      const titlePayload: any = {};
      titlePayload.titleType = title.type;
      titlePayload.name = title.name;
      titlePayload.licenseStart = bulkFill
        ? values.dynamicFields[0].start_date
        : values.dynamicFields[index].start_date;
      titlePayload.licenseEnd = bulkFill
        ? values.dynamicFields[0].end_date
        : values.dynamicFields[index].end_date;
      titlePayload.dealName =
        (bulkFill ? values.dynamicFields[0].rate : values.dynamicFields[index].rate) || '';
      titlePayload.titleCatalogId = title.sourceCatalog === 'WBTVD' ? title.mpmNumber : title._id;
      titlePayload.titleEidrId = title.eidrId || '';
      if (title.type === 'EPISODE') {
        titlePayload.seasonNumber = title.seasonNumber;
        titlePayload.episodeNumber = title.episodeNumber;
        titlePayload.seriesName = title.series?.name || '';
      }
      if (assets && assets[title._id]) {
        const filenames = assets[title._id].filenames;
        const assetSystems = assets[title._id].assetSystems;
        const systemKeys = Object.keys(filenames);
        titlePayload.deliveryFormats = systemKeys.map((key: string) => {
          let editAboveId: string | undefined = undefined;
          title.edits.forEach((edit: Edit) => {
            if (edit.type === assetSystems[key].editVersion) {
              editAboveId = edit.id;
            }
          });
          const system = assetSystems[key];
          if (system.assetsType === 'mezzanine') {
            return {
              deliveryType: 'MEZZANINE',
              poDestination: assetSystems[key].destination || '',
              includeVideo: assetSystems[key]?.includeVideo === false ? false : true,
              titleCard: assetSystems[key].titleCard || '',
              editAboveId: editAboveId || '',
              requireHd: assetSystems[key]?.requireHd === true ? true : false,
              require16x9: assetSystems[key]?.require16x9 === true ? true : false,
              filename: filenames[key],
              editVersion: assetSystems[key].editVersion,
              audioLanguages: assetSystems[key].audioLanguages,
              subtitleLanguages: assetSystems[key].subtitleLanguages,
              ifeSystem: key
            };
          } else if (system.assetsType !== 'mezzanine' && systemsMap) {
            return {
              deliveryType: 'ENCODE',
              poDestination: systemsMap[key].poDestination,
              filename: filenames[key],
              editVersion: assetSystems[key].editVersion,
              audioLanguages: assetSystems[key].audioLanguages,
              subtitleLanguages: assetSystems[key].subtitleLanguages,
              ifeSystem: key,
              includeVideo: assetSystems[key]?.includeVideo === false ? false : true,
              editAboveId: editAboveId || '',
              ifeFormat: systemsMap[key].ifeFormat,
              vtkTemplate: systemsMap[key].vtkTemplate,
              titleCard: assetSystems[key].titleCard || ''
            };
          }
        });
        titlesPayload.push(titlePayload);
      } else {
        titlePayload.deliveryFormats = [];
        titlesPayload.push(titlePayload);
      }
    });
    payload.titles = titlesPayload;

    if (!isSubmitting) {
      setIsSubmitting(true);
      createOrder(
        payload,
        () => {
          toast.success('Your order was successfully created!');
          navigate('/orders', { state: { refetch: true } });
          setIsSubmitting(false);
        },
        () => {
          setIsSubmitting(false);
        }
      );
    }
  };

  const onCycleCreate = (newOrderGroupId: string) => {
    setValue('orderGroupId', newOrderGroupId);
    populateOrderGroups();
    reset(getValues());
  };

  return (
    <div className="flex flex-col gap-8">
      <Helmet>
        <title>Above Media | Create a new order</title>
      </Helmet>
      <Header />
      <Breadcrumbs items={breadcrumbItems} />
      <form className="bg-white mb-32 container mx-auto border-2 border-gray-200 rounded-xl relative">
        {loading && (
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-white bg-opacity-70 z-50 flex items-center justify-center">
            <Loader /> Loading...
          </div>
        )}
        <div className="p-5 flex flex-row gap-4 items-center border-b border-gray-200">
          <div className="grow">
            <h3 className="font-semibold text-lg text-gray-900">Order Details</h3>
            <p className="text-gray text-sm">Fill out your order details here.</p>
          </div>
        </div>
        <div className="relative">
          {orderAssetMode && (
            <AssetsView
              userOrgs={userOrgs}
              orderGroupId={orderGroupId}
              organization={organization}
            />
          )}
          <div className="relative flex flex-col divide-y divide-gray-100">
            <FormItem label="Organization">
              <Controller
                name="organization"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Organization is required!'
                  }
                }}
                render={({ field }) => (
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex flex-row gap-4">
                      <Select
                        className="w-3/6"
                        isInvalid={Object.keys(formState.errors).indexOf('organization') !== -1}
                        options={orgOptions}
                        isOptionDisabled={(option: any) => option.disabled}
                        placeholder={`Please select organization`}
                        onChange={(option: any) => {
                          field.onChange(option.value);
                          setSavedOrg(option.value);
                          dispatch(resetAssets());
                        }}
                        value={orgOptions.find((option) => field.value === option.value)}
                      />
                    </div>
                    {formState.errors.organization && (
                      <div className="text-danger-500 mt-1.5 text-sm">
                        {(formState.errors.organization as FieldError).message}
                      </div>
                    )}
                    <div className="transition text-sm text-gray-400 py-1">
                      Changing this field value will reset order assets.
                    </div>
                  </div>
                )}
              />
            </FormItem>
            <FormItem label="Order Group" help="Select or create group (cycle)">
              <Controller
                name="orderGroupId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Order group is required!'
                  }
                }}
                render={({ field }) => (
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex flex-row gap-4">
                      <Select
                        isInvalid={Object.keys(formState.errors).indexOf('orderGroup') !== -1}
                        className="w-3/6"
                        options={orderGroupOptions}
                        placeholder="Please select order group"
                        onChange={(option: any) => {
                          field.onChange(option.value);
                          dispatch(resetAssets());
                        }}
                        value={orderGroupOptions.find((option) => field.value === option.value)}
                      />
                      <Button
                        rounded
                        variant="default"
                        type="button"
                        onClick={() => setModalOpen(true)}>
                        New Group
                      </Button>
                    </div>
                    {formState.errors.orderGroup && (
                      <div className="text-danger-500 mt-1.5 text-sm">
                        {(formState.errors.orderGroup as FieldError).message}
                      </div>
                    )}
                    <div className="transition text-sm text-gray-400 py-1">
                      Changing this field value will reset order assets.
                    </div>
                  </div>
                )}
              />
            </FormItem>
            <FormItem label="PO Number">
              <Input
                rounded
                className="w-3/6"
                placeholder="Enter your PO number here"
                error={(formState.errors.externalId as FieldError)?.message}
                {...register('externalId', {
                  required: {
                    value: true,
                    message: 'PO Number is required!'
                  }
                })}
              />
            </FormItem>
            <FormItem label="Brand">
              <Controller
                name="brand"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Brand is required!'
                  }
                }}
                render={({ field }) => (
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex flex-row gap-4">
                      <Select
                        isInvalid={Object.keys(formState.errors).indexOf('brand') !== -1}
                        className="w-2/6"
                        options={brandOptions}
                        placeholder="Please select brand"
                        onChange={(option: any) => {
                          field.onChange(option.value);
                        }}
                        value={brandOptions.find((option) => field.value === option.value)}
                      />
                    </div>
                    {formState.errors.brand && (
                      <div className="text-danger-500 mt-1.5 text-sm">
                        {(formState.errors.brand as FieldError).message}
                      </div>
                    )}
                  </div>
                )}
              />
            </FormItem>
          </div>
          <div className="px-4 pb-8 border-t border-b border-gray-200">
            <div className="flex flex-row items-center gap-4 border-b border-gray-200">
              <h2 className="grow font-semibold py-5 text-lg ">Titles</h2>
              <div className="flex-none flex flex-row gap-2 items-center">
                <span className="text-gray-500 font-medium">Bulk Fill</span>
                <Switch
                  checked={bulkFill}
                  onCheckedChange={(checked) => {
                    titles.map((item: Title, index) => {
                      setValue(`dynamicFields.${index}.start_date`, firstStartDateField);
                      setValue(`dynamicFields.${index}.end_date`, firstEndDateField);
                      setValue(`dynamicFields.${index}.rate`, firstDealRateField);
                    });
                    setBulkFill(checked);
                  }}
                />
                <Button
                  rounded
                  variant="default"
                  type="button"
                  disabled={!orderGroupId}
                  onClick={() => dispatch(setOrderAssetMode('add'))}>
                  Add Assets
                </Button>
              </div>
            </div>

            {titles.map((title: Title, index: number) => (
              <TitleRow
                title={title}
                control={control}
                key={index}
                bulkFill={bulkFill}
                formState={formState}
                firstStartDateField={firstStartDateField}
                firstEndDateField={firstEndDateField}
                firstDealRateField={firstDealRateField}
                setValue={setValue}
                getValues={getValues}
                isFirst={index === 0}
                index={index}
              />
            ))}

            <div className="flex flex-row-reverse gap-2 pt-12">
              <Button
                variant="primary"
                rounded
                size="lg"
                type="button"
                onClick={() => handleSubmit(onOrderSubmit)()}>
                <CartIcon className="stroke-white mr-1" /> Order
              </Button>
              <Button variant="defaultOutlined" rounded size="lg" href="/catalog">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
      {modalOpen && (
        <AddCycleFormModal
          onCycleCreate={onCycleCreate}
          selectedOrg={selectedOrg}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
}

const TitleRow = (props: {
  title: Title;
  control: Control;
  key: number;
  bulkFill: boolean;
  formState: any;
  firstStartDateField: string;
  firstEndDateField: string;
  firstDealRateField: string;
  setValue: any;
  getValues: any;
  isFirst: boolean;
  index: number;
}) => {
  const { assets } = useSelector((state: RootState) => state.order);

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-7 pt-8 pb-4 gap-4">
        <div className="col-span-3">
          <TitleLabel catalogTitle={props.title} />
        </div>
        <div className="col-span-2 flex justify-end items-center gap-2">
          <CycleSelector
            name={'start_date'}
            title={'License start'}
            index={props.index}
            control={props.control}
            bulkFill={props.bulkFill}
            formState={props.formState}
            firstField={props.firstStartDateField}
            isFirst={props.isFirst}
            setValue={props.setValue}
            getValues={props.getValues}
            useEndOfMonth={false}
          />
          <span>&ndash;</span>
          <CycleSelector
            name="end_date"
            title={'License end'}
            index={props.index}
            control={props.control}
            bulkFill={props.bulkFill}
            formState={props.formState}
            firstField={props.firstEndDateField}
            isFirst={props.isFirst}
            setValue={props.setValue}
            getValues={props.getValues}
            useEndOfMonth={true}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name={`dynamicFields.${props.index}.rate`}
            control={props.control}
            render={({ field }) => (
              <Input
                rounded
                placeholder="Enter deal name / rate"
                value={!props.isFirst && props.bulkFill ? props.firstDealRateField : field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={!props.isFirst && props.bulkFill}
                error={
                  !props.isFirst && props.bulkFill
                    ? false
                    : props.formState.errors.dynamicFields &&
                      (props.formState.errors.dynamicFields as any)[props.index]?.rate?.message
                }
              />
            )}
          />
        </div>
      </div>
      {assets &&
        assets[props.title._id] &&
        Object.keys(assets[props.title._id].filenames).map((key: string, sysindex) => (
          <div
            className="border-b border-gray-200 grid grid-cols-8 py-1 gap-4 text-sm text-gray-900 font-light items-center"
            key={sysindex}>
            <div className="col-span-3 capitalize">
              {assets[props.title._id].assetSystems[key].systemLabel ||
                `${assets[props.title._id].assetSystems[key].destination?.replace(/_/, ' ')} - ${key}`}
            </div>
            <div className=" col-span-3">
              <div key={key} className={`flex flex-row gap-2 items-center`}>
                <span className="flex flex-row items-center gap-2">
                  <span className="font-bold">
                    {assets[props.title._id].assetSystems[key].editVersion}
                  </span>{' '}
                  |
                  {assets[props.title._id].assetSystems[key].subtitleLanguages.length > 0 && (
                    <span className="flex flex-wrap gap-1 items-center">
                      <span>subs: </span>
                      <Tag
                        key={`${key}-subs`}
                        variant="default"
                        size="xs"
                        className={`rounded-none uppercase p-1`}>
                        {assets[props.title._id].assetSystems[key].subtitleLanguages.join(', ')}
                      </Tag>
                    </span>
                  )}
                  {assets[props.title._id].assetSystems[key].audioLanguages.length > 0 && (
                    <span className="flex flex-wrap gap-1 items-center">
                      <span>dubs: </span>
                      <Tag
                        key={`${key}-dubs`}
                        variant="default"
                        size="xs"
                        className={`rounded-none uppercase p-1`}>
                        {assets[props.title._id].assetSystems[key].audioLanguages.join(', ')}
                      </Tag>
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="italic">{assets[props.title._id].filenames[key]}</div>
            <div className="items-end justify-end flex">
              <ActionSet item={props.title} id={key} />
            </div>
          </div>
        ))}
    </div>
  );
};

const CycleSelector = (props: {
  name: string;
  title: string;
  index: number;
  control: Control;
  bulkFill: boolean;
  formState: any;
  firstField: string;
  isFirst: boolean;
  getValues: any;
  setValue: any;
  useEndOfMonth: boolean;
}) => {
  const [cycleSelectorOpen, setCycleSelectorOpen] = useState<boolean>(false);
  const today = startOfToday();
  const months = eachMonthOfInterval({
    start: startOfMonth(today),
    end: startOfMonth(addYears(today, 2))
  });
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setCycleSelectorOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <Popover open={cycleSelectorOpen}>
        <PopoverTrigger>
          <Controller
            name={`dynamicFields.${props.index}.${props.name}`}
            control={props.control}
            rules={{
              required: {
                value: props.isFirst || !props.bulkFill,
                message: `${capitalize(props.name.replace('_', ' '))} is required!`
              }
            }}
            render={({ field }) => (
              <Button
                type="button"
                variant="default"
                rounded
                block
                title={props.title}
                disabled={!props.isFirst && props.bulkFill}
                onClick={() => setCycleSelectorOpen(!cycleSelectorOpen)}>
                <CalendarIcon />
                {(!props.isFirst && props.bulkFill ? props.firstField : field.value) ? (
                  <div>
                    {new Date(
                      !props.isFirst && props.bulkFill ? props.firstField : field.value
                    ).toLocaleDateString(undefined, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    })}
                  </div>
                ) : (
                  <span className="text-gray-500">{props.title}</span>
                )}
              </Button>
            )}
          />
        </PopoverTrigger>
        <PopoverDropdownContent align={'center'}>
          <Controller
            name={`dynamicFields.${props.index}.${props.name}`}
            control={props.control}
            render={({ field }) => (
              <select
                size={6}
                value={!props.isFirst && props.bulkFill ? props.firstField : field.value}
                onChange={(e) => {
                  field.onChange(e);
                  props.setValue(
                    `dynamicFields.${props.index}.${props.name}_day`,
                    new Date(e.target.value).getDate()
                  );
                  setCycleSelectorOpen(false);
                }}
                onBlur={field.onBlur}
                className="mt-1 block w-full py-2 px-3 focus:outline-0">
                {months.map((month, index) => (
                  <option
                    key={index}
                    value={format(props.useEndOfMonth ? endOfMonth(month) : month, 'yyyy-MM-dd')}
                    className="p-1 mx-auto flex h-10 w-full items-center rounded text-sm font-medium text-gray-700 checked:bg-gray-50 checked:font-semibold">
                    {format(month, 'yyyy MMMM')}
                  </option>
                ))}
              </select>
            )}
          />
          {props.getValues(`dynamicFields.${props.index}.${props.name}_day`) && (
            <Controller
              name={`dynamicFields.${props.index}.${props.name}_day`}
              control={props.control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex gap-4 items-center border-t border-gray-100 pt-3 pb-3 px-2">
                  <label htmlFor="day" className="block text-sm font-medium text-gray-700">
                    Day
                  </label>
                  <input
                    {...field}
                    className="w-12 h-10 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <Button
                    variant="primary"
                    rounded
                    disabled={
                      parseInt(props.getValues(`dynamicFields.${props.index}.${props.name}_day`)) <
                        1 ||
                      parseInt(props.getValues(`dynamicFields.${props.index}.${props.name}_day`)) >
                        getDate(
                          endOfMonth(
                            new Date(props.getValues(`dynamicFields.${props.index}.${props.name}`))
                          )
                        )
                    }
                    onClick={() => {
                      const startDate = new Date(
                        props.getValues(`dynamicFields.${props.index}.${props.name}`)
                      );
                      const updatedStartDate = setDate(startDate, field.value);
                      props.setValue(
                        `dynamicFields.${props.index}.${props.name}`,
                        format(updatedStartDate, 'yyyy-MM-dd')
                      );
                      setCycleSelectorOpen(false);
                    }}>
                    Apply
                  </Button>
                </div>
              )}
            />
          )}
        </PopoverDropdownContent>
      </Popover>
      {(props.isFirst || !props.bulkFill) && (
        <div className="text-danger-500 mt-1.5 text-sm">
          {props.formState.errors.dynamicFields &&
            props.formState.errors.dynamicFields.length > props.index &&
            props.formState.errors.dynamicFields[props.index] &&
            typeof props.formState.errors.dynamicFields[props.index] === 'object' &&
            props.name in props.formState.errors.dynamicFields[props.index] &&
            (props.formState.errors.dynamicFields as any)[props.index][props.name]?.message}
        </div>
      )}
    </div>
  );
};
