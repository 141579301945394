import { useEffect, useState } from 'react';
import Select from '../ui/Select';
import DeleteIcon from '../../assets/icons/delete.svg?react';
import MoveIcon from '../../assets/icons/move.svg?react';
import Button from '../ui/Button';
import clsx from 'clsx';

export interface LanguageOption {
  label: string;
  value: string;
  id: number;
}

export interface OrderedLanguageItem extends LanguageOption {
  order?: number;
}

export interface LanguagePickerProps {
  languages: LanguageOption[];
  selectedLanguages?: OrderedLanguageItem[];
  onLanguageChange?: (selectedLanguages: LanguageOption[]) => void;
  featuredLangs?: string[];
  readOnly?: boolean;
  isInvalid?: boolean;
}

const LanguagePicker = (props: LanguagePickerProps) => {
  const [selectedLanguages, setSelectedLanguages] = useState(props.selectedLanguages || []);
  const orderedSelectedLanguages = selectedLanguages.map((item) => ({
    ...item,
    order: item.order || item.id
  }));
  const [dragId, setDragId] = useState<number>(0);
  const [dragPlaceholderIndex, setDragPlaceholderIndex] = useState<number | null>(null);
  const languages = props.languages;
  const onLanguageDelete = (language: LanguageOption) => {
    const newLanguages = orderedSelectedLanguages.filter((obj) => obj.id !== language.id);
    setSelectedLanguages(newLanguages);
    props.onLanguageChange && props.onLanguageChange(newLanguages);
  };

  const onLanguageSelect = (value: string | unknown) => {
    const language = languages.find((item) => item.id.toString() === value);
    if (language) {
      const newLanguages = [
        ...orderedSelectedLanguages.filter((obj) => obj.id !== language.id),
        language
      ];
      setSelectedLanguages(newLanguages);
      props.onLanguageChange && props.onLanguageChange(newLanguages);
    }
  };

  useEffect(() => {
    setSelectedLanguages(props.selectedLanguages as OrderedLanguageItem[]);
  }, [props.selectedLanguages]);

  const handleDrop = (id: number) => {
    if (dragId !== id) {
      const draggedItem = orderedSelectedLanguages[dragId];
      const updatedList = [...orderedSelectedLanguages];
      updatedList.splice(dragId, 1);
      updatedList.splice(id, 0, draggedItem);
      setSelectedLanguages(updatedList);
      props.onLanguageChange && props.onLanguageChange(updatedList);
    }
    setDragPlaceholderIndex(null);
  };

  const handleDragOver = (index: number) => {
    setDragPlaceholderIndex(index);
  };

  return (
    <div className="flex flex-col w-full">
      {!props.readOnly && (
        <Select
          isInvalid={props.isInvalid}
          className="w-3/6"
          placeholder="Please select languages"
          onChange={(option: any) => onLanguageSelect(option.value)}
          value=""
          options={[
            {
              label: 'Featured languages',
              options: languages
                .filter((item) => props.featuredLangs?.includes(item.value))
                .filter((item) => selectedLanguages.map((item) => item.id).indexOf(item.id) === -1)
                .map((language) => ({
                  label: language.label,
                  value: language.id.toString()
                }))
            },
            {
              label: '',
              options: languages
                .filter((item) => !props.featuredLangs?.includes(item.value))
                .filter((item) => selectedLanguages.map((item) => item.id).indexOf(item.id) === -1)
                .map((language) => ({
                  label: language.label,
                  value: language.id.toString()
                }))
            }
          ]}
        />
      )}
      {orderedSelectedLanguages.length > 0 && (
        <div className="overflow-hidden flex flex-col  divide-y divide-gray-200 bg-white border rounded-lg border-gray-200 mt-2">
          {orderedSelectedLanguages.map((language, index) => {
            return (
              <div key={index}>
                {index !== null && dragId > index && (
                  <div className={dragPlaceholderIndex === index ? 'bg-orange-100 h-1' : ''}></div>
                )}
                <div
                  key={language.id}
                  className={clsx('group flex flex-row gap-4 p-1 relative items-center', {
                    'bg-gray-50': index % 2 !== 0,
                    'border border-dashed border-gray-500': dragPlaceholderIndex === index,
                    'pl-12': !props.readOnly,
                    'p-2': props.readOnly
                  })}
                  onDragOver={(e) => {
                    e.preventDefault();
                    handleDragOver(index);
                  }}
                  onDragStart={(e) => {
                    setDragId(index);
                  }}
                  onDrop={() => handleDrop(index)}
                  draggable={!props.readOnly}>
                  {!props.readOnly && (
                    <div className="transition-all absolute left-3 hidden group-hover:block cursor-move">
                      <MoveIcon />
                    </div>
                  )}
                  <div className="grow flex flex-row gap-4">
                    <div className="font-medium text-sm">{language.label}</div>
                  </div>

                  {!props.readOnly && (
                    <div>
                      <Button link onClick={() => onLanguageDelete(language)}>
                        <DeleteIcon />
                      </Button>
                    </div>
                  )}
                </div>
                {index !== null && dragId < index && (
                  <div className={dragPlaceholderIndex === index ? 'bg-orange-100 h-1' : ''}></div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default LanguagePicker;
