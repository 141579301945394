import { Routes, Route, Navigate } from 'react-router-dom';
import IngestionRouter from './pages/ingestion/Router';
import RepositoryRouter from './pages/repository/Router';
import CatalogRouter from './pages/catalog/Router';
import AuthRouter from './pages/auth/Router';
import TitleCardsRouter from './pages/titleCard/Router';
import TermsAndConditionsPage from './pages/legal/TermsAndConditionsPage';
import OrdersRouter from './pages/orders/Router';
import SettingsRouter from './pages/settings/Router';
import { PresenceType, useIdleTimer } from 'react-idle-timer';
import { lightSignOut } from './common/auth';

function App() {
  const onPresenceChange = (presence: PresenceType) => {
    if (presence.type === 'idle') {
      console.info('Logging out user...');
      lightSignOut();
    }
  };
  useIdleTimer({ onPresenceChange, timeout: 1000 * 60 * 60 * 8 });

  return (
    <div className="App bg-gray-100 flex flex-col gap-8 min-h-screen">
      <Routes>
        <Route path="/catalog/*" element={<CatalogRouter />}></Route>
        <Route path="/repository/*" element={<RepositoryRouter />}></Route>
        <Route path="/orders/*" element={<OrdersRouter />}></Route>
        <Route path="/ingestion/*" element={<IngestionRouter />}></Route>
        <Route path="/auth/*" element={<AuthRouter />}></Route>
        <Route path="/title-cards/*" element={<TitleCardsRouter />}></Route>
        <Route path="/settings/*" element={<SettingsRouter />}></Route>
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />}></Route>
        <Route path="*" element={<Navigate to="/catalog" replace />} />
      </Routes>
    </div>
  );
}

export default App;
