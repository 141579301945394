export const getPO = `
  query GetPOQuery($poName: String!) {
    list_POs(input: {filter: {po_name: {eq: $poName}}}) {
      id
      pos {
        poitems {
          po_item_id
        }
      }
    }
  }
`;

export const getPOItem = `
  query GetPOItemQuery($poName: String!, $poItemId: String!) {
    list_POs(input: {filter: {po_name: {eq: $poName}}}) {
      pos {
        poitems(po_item_id: $poItemId) {
          po_item_id
          publish_process {
            id
            state
            message
          }
          workflow_process {
            id
            state
            message
          }
          preview {
            dash_manifest_url
            drmtoday_token
          }
        }
      }
    }
  }
`;
