import { useState } from 'react';
import Button from '../ui/Button';
import { AbovePlayer } from '../asset/AbovePlayer';
import { Order, OrderTitleDelivery } from '../../features/orders/types';
import { useGetPOItemQuery } from '../../features/orders/workflowApi';
import CheckIcon from '../../assets/icons/check.svg?react';
import AlertIcon from '../../assets/icons/alert.svg?react';
import Loader from '../../components/ui/Loader';
import { approveEncodePreview } from '../../features/orders/ordersApi';
import { formatDateTime } from '../../common/dates';

interface EncodePreviewProps {
  order: Order;
  delivery: OrderTitleDelivery;
  closePreview: () => void;
}

export const EncodePreview = (props: EncodePreviewProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: poitem, isLoading } = useGetPOItemQuery({
    poName: props.order.orderName,
    poItemId: props.delivery.deliveryId,
    organization: props.order.organization == 'touch-inflight' ? 'touch' : 'above'
  });

  const approvedHistoryLog = props.delivery.history.findLast(
    (hl) => hl.action === 'ENCODE_APPROVED'
  );

  const approvePreview = () => {
    setIsSubmitting(true);
    approveEncodePreview(props.order._id, props.delivery.deliveryId, () => {
      setIsSubmitting(false);
      props.closePreview();
    });
  };

  return (
    <div>
      <div className="">
        <Button
          onClick={props.closePreview}
          className="absolute right-2 top-[-30px] z-50 bg-gray-400 rounded-t px-4 py-1 text-white cursor-pointer text-base hover:bg-gray-500">
          Close
        </Button>
      </div>
      <div className="bg-gray bg-opacity-70 absolute left-0 right-0 top-0 min-h-full z-50 border-gray-400 border rounded-b">
        <div className="p-0">
          <div className="bg-gray-200 border-b border-gray-300 text-gray-900 p-4 text-left font-medium text-lg flex flex-row items-center gap-2 w-full">
            <p className="text-base">
              Preview of
              <span className="tracking-tighter font-mono ml-2">
                {props.delivery.deliveryFileName || ''}
              </span>
            </p>
          </div>
          <div className="my-0 bg-gray-25 divide-y divide-gray-200">
            {isLoading && (
              <div className="flex items-center justify-center py-24">
                <Loader />
              </div>
            )}
            {poitem?.preview.dash_manifest_url && poitem.preview.drmtoday_token && (
              <AbovePlayer
                dashManifestUrl={poitem.preview.dash_manifest_url}
                drmTodayToken={poitem.preview.drmtoday_token}
                showTracksSelection={true}
              />
            )}
            {!isLoading &&
              (!poitem?.preview.dash_manifest_url || !poitem.preview.drmtoday_token) && (
                <div className="flex items-center justify-center py-24">
                  <AlertIcon className="w-6 h-6 mr-2 stroke-danger" />
                  <span className="text-base text-gray-900">
                    The preview is no longer available
                  </span>
                </div>
              )}
            <div className="flex flex-row-reverse p-4 gap-2">
              {approvedHistoryLog && (
                <div className="text-gray-900">
                  Encode preview approved by{' '}
                  <span className="font-semibold">{approvedHistoryLog.userFullName}</span>
                  {' on '}
                  <span className="font-semibold">
                    {formatDateTime(approvedHistoryLog.timestamp)}
                  </span>
                </div>
              )}
              {!approvedHistoryLog && (
                <Button
                  disabled={isLoading || isSubmitting}
                  rounded
                  variant="primary"
                  className="flex flex-row gap-1"
                  onClick={approvePreview}>
                  <CheckIcon className="stroke-white" /> Approve
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
