import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import Button from '../ui/Button';
import { VUE_APP_HOST } from '../../common/constants';
import Logo from '../../assets/above-logo.svg?react';
import AvatarFilledIcon from '../../assets/icons/avatar-filled.svg?react';
import LogoutIcon from '../../assets/icons/logout.svg?react';
import SettingsIcon from '../../assets/icons/settings.svg?react';
import { Tooltip } from '../ui/Tooltip';
import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';
import PushSubscription from '../webpush/PushSubscription';
import { useUserContext } from '../../common/providers/UserProvider';
import { lightSignOut } from '../../common/auth';

const MenuItem = ({
  active,
  ...props
}: {
  active?: boolean;
  to: string;
  badge?: string;
  onClick?: () => void;
  children?: ReactNode;
}) => {
  const menuItemClasses = clsx(
    'transition text-primary-700 py-2 px-3 rounded-lg cursor-pointer block',
    {
      'bg-primary text-white': active,
      'hover:bg-primary-50 hover:text-primary-700 font-normal': !active,
      'mr-2': props.badge
    }
  );
  return (
    <li>
      <Link className={menuItemClasses} to={props.to}>
        {props.children}
        {props.badge && (
          <span className="relative -left-5">
            <span className="bg-success-500 text-white text-xs px-1 rounded-lg ml-1 absolute -top-4">
              {props.badge}
            </span>
          </span>
        )}
      </Link>
    </li>
  );
};

const menuItems: {
  label: string;
  link: string;
  pathname: string;
  userGroups: string[];
  deprecated: boolean;
  badge?: string;
}[] = [
  {
    label: 'Catalog',
    link: `/catalog`,
    pathname: '/catalog',
    userGroups: ['above_admin', 'above_ops', 'csp_user', 'studio_admin'],
    deprecated: false,
    badge: 'new'
  },
  {
    label: 'Orders',
    link: `/orders`,
    pathname: '/orders',
    userGroups: ['above_admin', 'above_ops', 'studio_admin', 'csp_user'],
    deprecated: false,
    badge: 'new'
  },
  {
    label: 'Ingestion',
    link: `/ingestion`,
    pathname: '/ingestion',
    userGroups: ['basic'],
    deprecated: false
  },
  {
    label: 'Repository',
    link: `/repository`,
    pathname: '/repository',
    userGroups: ['above_admin'],
    deprecated: false,
    badge: 'new'
  },
  {
    label: 'Title Cards',
    link: `/title-cards`,
    pathname: '/title-cards',
    userGroups: ['above_admin', 'above_ops'],
    deprecated: false
  },
  {
    label: 'Catalog',
    link: `${VUE_APP_HOST}/catalog`,
    pathname: '/catalog',
    userGroups: ['basic'],
    deprecated: true
  },
  {
    label: 'Purchase Orders',
    link: `${VUE_APP_HOST}/orders`,
    pathname: '/orders',
    userGroups: ['basic'],
    deprecated: true
  },
  {
    label: 'Orders Management',
    link: `${VUE_APP_HOST}/orders/orders-titles`,
    pathname: '/orders/orders-titles',
    userGroups: ['basic'],
    deprecated: false
  }
];

export default function Header({ publicPage }: { publicPage?: boolean }) {
  const location = useLocation();
  const { user } = useUserContext();
  const currentUserGroups = useAppSelector(selectUserGroups);
  const handleLogout = () => {
    lightSignOut();
  };
  return (
    <div className="bg-white p-4 border-primary-700 border-b-4 shadow-header">
      <div className="container mx-auto flex flex-row gap-10 items-center">
        <a href="" className="flex-none w-40">
          <Logo />
        </a>
        <div className="grow">
          {!publicPage && (
            <ul className="flex flex-row font-medium items-center gap-1">
              {menuItems.map(
                (item) =>
                  currentUserGroups &&
                  currentUserGroups.some((group) => item.userGroups.includes(group)) && (
                    <MenuItem
                      key={item.link}
                      to={item.link}
                      active={!item.deprecated && location.pathname.indexOf(item.pathname) !== -1}
                      badge={item.badge}>
                      {item.label}
                    </MenuItem>
                  )
              )}
            </ul>
          )}
        </div>
        {!publicPage && (
          <div className="flex-none flex flex-row">
            <Tooltip
              content="Subscribe / Unsubscribe to push notifications"
              className="bg-black text-white"
              arrowClassName="fill-black"
              asChild>
              <PushSubscription />
            </Tooltip>
            <Tooltip content="Settings" className="bg-black text-white" arrowClassName="fill-black">
              <Button href="/settings" rounded link className="hover:bg-gray-50">
                <SettingsIcon className="w-5 h-5 stroke-primary-700" />
              </Button>
            </Tooltip>
            <Tooltip content="Logout" className="bg-black text-white" arrowClassName="fill-black">
              <Button onClick={() => handleLogout()} rounded link className="hover:bg-gray-50">
                <LogoutIcon className="w-5 h-5 stroke-primary-700" />
              </Button>
            </Tooltip>
            {user?.username ? (
              <Tooltip
                content={user.username}
                className="bg-black text-white"
                arrowClassName="fill-black">
                {user.emailHash ? (
                  <img
                    src={`https://gravatar.com/avatar/${user.emailHash}?d=robohash`}
                    className="h-10 w-10 ml-2 rounded-full border border-gray-300"
                  />
                ) : (
                  <AvatarFilledIcon className="w-10 h-10 ml-2 cursor-pointer" />
                )}
              </Tooltip>
            ) : (
              <AvatarFilledIcon className="w-10 h-10 ml-2" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
