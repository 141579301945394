const VUE_APP_HOST_PROTOCOL = import.meta.env.VITE_VUE_APP_HOST.startsWith('localhost')
  ? 'http://'
  : 'https://';
export const VUE_APP_HOST = `${VUE_APP_HOST_PROTOCOL}${import.meta.env.VITE_VUE_APP_HOST}`;

export const API_GATEWAY_URL = import.meta.env.VITE_API_GATEWAY_URL;

export const VITE_APPSYNC_API_URL = import.meta.env.VITE_APPSYNC_API_URL;

export const WORKFLOW_API_URL = import.meta.env.VITE_WORKFLOW_API_URL;

export const NODE_ENV = import.meta.env.VITE_NODE_ENV;

export const CONTENT_PORTAL_URL = import.meta.env.VITE_CONTENT_PORTAL_URL;
