import { RefObject } from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import SearchIcon from '../../assets/icons/search.svg?react';

export default function OrgsFilters({
  onSearch,
  search,
  queryRef
}: {
  onSearch: (query: string) => void;
  search?: string;
  queryRef: RefObject<HTMLInputElement>;
}) {
  const handleSearch = () => {
    if (queryRef && queryRef.current) {
      onSearch && onSearch(queryRef.current.value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      <div className="container mx-auto flex flex-col items-stretch gap-2 border border-primary-300 bg-primary-200 p-3 rounded-lg">
        <div className="flex flex-row   gap-2">
          <Input
            rounded
            className="grow"
            defaultValue={search}
            prefixNode={<SearchIcon className="stroke-gray-400" />}
            placeholder="Search by name, email address or username"
            ref={queryRef}
            onKeyDown={handleKeyDown}
          />
          <Button rounded variant="primary" onClick={() => handleSearch()}>
            Search
          </Button>
        </div>
      </div>
    </div>
  );
}
