import {
  AspectRatio,
  DerivedOrderDeliveryStatus,
  DeliveryItemStatus,
  OrderDeliveryStatus,
  OrderLicenseStatus,
  Quality,
  TargetIFE,
  TargetIFEFormat
} from '../../common/types';
import CheckIcon from '../../assets/icons/check.svg?react';
import CloseIcon from '../../assets/icons/close.svg?react';
import MinusIcon from '../../assets/icons/minus.svg?react';
import { ReactNode } from 'react';
import { Meta, OrderItemTitleType } from '../../common/types';

export interface StatusMetadata {
  variant: 'success' | 'default' | 'error' | 'primary' | 'warning' | 'secondary' | 'info';
  icon?: ReactNode;
}

export const ORDER_DELIVERY_STATUS_METADATA: Record<DerivedOrderDeliveryStatus, StatusMetadata> = {
  NO_DELIVERY_NEEDED: { variant: 'success' },
  WAITING_FOR_LICENSE: { variant: 'default' },
  IN_PROGRESS: { variant: 'info' },
  SUCCESS: { variant: 'success' },
  FAILED: { variant: 'error' },
  CANCELLED: { variant: 'error' },
  MISSING_CONTENT: { variant: 'error' }
} as const;

export const ORDER_LICENSE_STATUS_METADATA: Record<OrderLicenseStatus, StatusMetadata> = {
  APPROVED: {
    variant: 'success',
    icon: <CheckIcon className="stroke-success-700 w-4 h-4" />
  },
  REJECTED: {
    variant: 'error',
    icon: <CloseIcon className="stroke-danger-700 w-4 h-4" />
  },
  PENDING: {
    variant: 'default',
    icon: <MinusIcon className="stroke-gray-700 w-4 h-4" />
  }
} as const;

export interface Organization {
  name: string;
  short_id: string;
}

export interface EncodingOption {
  text: string;
  value: string;
}

export interface BaseUser {
  email: string;
  username: string;
  organization: Organization | null;
}

export interface Encoding {
  audio: Language[];
  subtitles: Language[];
  cc: Language[];
  ar: AspectRatio;
  quality: Quality;
  ife: Omit<TargetIFE, 'formats'>;
  ife_format: Omit<TargetIFEFormat, 'id' | 'vtk_template' | 'target_ife'>;
}

export type OrderItemEncoding = Omit<Encoding, 'ife' | 'ife_format'>;

export interface BaseOrder {
  id: number;
  created_by: BaseUser;
  airline: Airline | null;
  created: string;
  delivery_target: string;
  destination: Omit<Destination, 'organizations'> | null;
  order_id: string;
  organization: Organization;
  external_id?: string;
  target_ife_format: TargetIFEFormat | null;
  cycle: string;
}

export interface Language {
  language: string;
  status: string;
}

export interface Airline {
  name: string;
  short_id: string;
}

export interface Destination {
  name: string;
  code: string;
}

export enum ContentErrorType {
  ASSET_NOT_FOUND = 'ASSET_NOT_FOUND',
  ASSET_NOT_QCED = 'ASSET_NOT_QCED',
  NO_HD_QUALITY = 'NO_HD_QUALITY',
  NO_16X9_AR = 'NO_16X9_AR'
}

export enum AssetType {
  AUDIO = 'AUDIO',
  SUBTITLE = 'SUBTITLE',
  VIDEO = 'VIDEO',
  CLOSED_CAPTION = 'CLOSED_CAPTION',
  UNKNOWN = 'UNKNOWN'
}

export interface ContentError {
  assetType: AssetType;
  language?: string;
  error: ContentErrorType;
  description: string;
}

export interface OrderTitleDelivery {
  deliveryId: string;
  deliveryType: string;
  editVersion: string;
  poDestination: string;
  audioLanguages: string[];
  subtitleLanguages: string[];
  includeVideo: boolean;
  status: string;
  contentErrors?: ContentError[];
  history: History[];
  createdAt: string;
  modifiedAt: string;
  deliveryFileName?: string;
  ifeSystem?: string;
  editAboveId?: string;
  requireHd?: boolean | null;
  require16x9?: boolean | null;
  ifeFormat?: string;
  vtkTemplate?: string;
  titleCard?: string;
}

export interface Title {
  name: string;
  titleType: string;
  titleIndex: number;
  titleCatalogId: string;
  titleEidrId: string;
  licenseStart: string;
  licenseEnd: string;
  dealName: string;
  seriesName: string | null;
  seasonNumber: string | null;
  episodeNumber: string | null;
  deliveries: OrderTitleDelivery[];
}

export interface History {
  user: string;
  userFullName: string | null;
  userOrganization: string | null;
  userOrganizationName: string | null;
  action: string;
  message: string;
  timestamp: string;
}

export interface TitleMeta {
  episodeNumber: number;
  name: string;
  seasonNumber: number;
  seriesName: string;
  titleType: OrderItemTitleType;
}

export interface Order {
  _id: string;
  orderName: string;
  organization: string;
  sourceCatalog: string;
  orderGroup: OrderGroup;
  createdBy: string;
  licenseStatus: OrderLicenseStatus;
  deliveryStatus: OrderDeliveryStatus;
  titles: Title[];
  brand: string;
  history: History[];
  createdAt: string;
  modifiedAt: string;
  externalId: string;
}

export interface OrderGroup {
  _id: string;
  orgId: string;
  title: string;
  clientId: string;
  clientText: string;
  createdAt: string;
}

export type CreateOrderGroup = Omit<OrderGroup, '_id' | 'createdAt'>;

export interface GetOrdersResponse {
  listOrders: { results: Order[]; meta: Meta | null };
}

export interface GetOrderResponse {
  getOrderById: Order;
}

export interface ApproveOrderResponse {
  approveOrder: Order;
}

export interface RejectOrderResponse {
  rejectOrder: Order;
}

export interface RestartOrderDeliveryResponse {
  restartOrderDelivery: Order;
}

export interface GetOrderGroupsResponse {
  listOrderGroups: OrderGroup[];
}

export interface UpdateOrderTitleDeliveryInput {
  audioLanguages: string[];
  subtitleLanguages: string[];
  deliveryFileName: string;
}

export interface UpdateOrderInput {
  externalId: string;
  brand: string;
}

export interface UpdateOrderStatusOutput {
  _id: string;
  organization: string;
  licenseStatus: OrderLicenseStatus;
  deliveryStatus: OrderDeliveryStatus;
  externalId: string | null;
  brand: string | null;
  titles: {
    deliveries: {
      deliveryId: string;
      status: DeliveryItemStatus;
      audioLanguages: string[];
      subtitleLanguages: string[];
      deliveryFileName: string;
      history: History[];
    }[];
  }[];
}

export interface CastlabsPOItem {
  po_item_id: string;
  publish_process: {
    id: string;
    state: string;
    message: string;
  };
  workflow_process: {
    id: string;
    state: string;
    message: string;
  };
  preview: {
    dash_manifest_url: string | null;
    drmtoday_token: string | null;
  };
}

export interface CastlabsPO {
  poitems: CastlabsPOItem[];
}
