import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { usePostAnalyzeFilesMutation } from '../../features/ingestion/ingestionApi';
import {
  clearContentMetadata,
  selectIngest,
  setDetectedFiles,
  setTitleCandidates,
  setPaths,
  setPreselectedMpmNumber,
  setEditVersion
} from '../../features/ingestion/ingestSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ApiErrorAlert from '../../components/shared/ApiErrorAlert';
import { EditVersion, EditVersionLabels } from '../../common/types';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import Button from '../../components/ui/Button';
import FormItem from '../../components/ui/FormItem';
import Textarea from '../../components/ui/Textarea';
import Loader from '../../components/ui/Loader';
import FileLocationsIcon from '../../assets/icons/file-locations.svg?react';
import FindTitleIcon from '../../assets/icons/find-title.svg?react';
import ConfigureAssetsIcon from '../../assets/icons/configure-assets.svg?react';
import HomeIcon from '../../assets/icons/home.svg?react';
import { Helmet } from 'react-helmet-async';
import { Collapse, CollapseSection } from '../../components/ui/Collapse';

const isPath = (path: string) => /^(https?|s3):\/\/[\w-.]+(:\d+)?(\/[^/:]+)*\/?$/i.test(path);

const splitPaths = (paths: string) =>
  paths
    .split('\n')
    .map((path) => path.trim())
    .filter((path) => path);

export default function IngestFileLocationsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const ingest = useAppSelector(selectIngest);
  const [analyseFilesTrigger, { isLoading, isError, error }] = usePostAnalyzeFilesMutation();

  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    defaultValues: {
      paths: ingest.paths.join('\n')
    }
  });

  const onSubmit: SubmitHandler<{ paths: string }> = (values) => {
    const rows = splitPaths(values.paths);
    dispatch(setPaths(rows));
    dispatch(clearContentMetadata());
    analyseFilesTrigger(rows).then((response) => {
      if ('error' in response) return;

      const files = response.data.files.map((file) => ({
        ...file,
        language: file.language === 'und' ? '' : file.language,
        included: true
      }));
      dispatch(setDetectedFiles(files));
      dispatch(setTitleCandidates(response.data.titleCandidates));
      navigate('/ingestion/new/content-metadata');
    });
  };

  const loadPreselectedOptions = () => {
    const path = searchParams.get('path') || '';
    const mpmNumber = searchParams.get('mpm') || '';
    const editVersion = searchParams.get('version') || '';

    setValue('paths', path);

    if (path.length > 0) {
      trigger('paths');
    }

    if (mpmNumber) {
      dispatch(setPreselectedMpmNumber(mpmNumber));
    }
    if (editVersion in Object.keys(EditVersionLabels)) {
      dispatch(setEditVersion(editVersion as EditVersion));
    }
  };

  useEffect(() => {
    if (window.location.search) {
      loadPreselectedOptions();
    }
  }, []);

  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Ingestion',
      link: '/ingestion'
    },
    {
      title: 'New'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Above Media | Ingestion | File locations</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8">
        <div className="px-5 py-5 flex flex-row gap-4 items-center justify-center border-b border-gray-200">
          <div className="grow gap-1 flex flex-col font-semibold text-lg text-gray-900">
            New Ingestion Process
            <p className="text-gray font-normal text-sm">Fill out your ingestion details here.</p>
          </div>
        </div>
        <div className="relative flex flex-col divide-y divide-gray-100">
          <Collapse activeSection="file-locations" urlMode={true}>
            <CollapseSection
              icon={<FileLocationsIcon className="w-10 h-10" />}
              id="file-locations"
              title="Validate File Locations">
              {isError && (
                <div className="p-4">
                  <ApiErrorAlert error={error} />
                </div>
              )}
              {isLoading && (
                <div className="absolute z-50 bg-white bg-opacity-60 top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                  <Loader />
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <FormItem
                  layout="vertical"
                  help="Enter a list of S3 file or folder keys for a title that you want to ingest. One key per line.">
                  <Textarea
                    rounded
                    rows={12}
                    placeholder="One key per line"
                    error={formState.errors.paths?.message}
                    {...register('paths', {
                      required: true,
                      validate: (paths) => {
                        return (
                          !splitPaths(paths).some((path) => !isPath(path)) ||
                          'You have entered an invalid path'
                        );
                      },
                      onChange: debounce(async () => {
                        await trigger('paths');
                      }, 200)
                    })}
                  />
                </FormItem>
                <div className="flex flex-row-reverse p-5 gap-4">
                  <Button
                    variant="primary"
                    rounded
                    type="submit"
                    size="lg"
                    disabled={!formState.isValid}>
                    Validate
                  </Button>
                </div>
              </form>
            </CollapseSection>
            <CollapseSection
              icon={<FindTitleIcon className="w-10 h-10" />}
              id="content-metadata"
              title="Find Title"
              active={false}>
              <span />
            </CollapseSection>
            <CollapseSection
              icon={<ConfigureAssetsIcon className="w-10 h-10" />}
              id="confirmation"
              title="Configure Assets"
              active={false}>
              <span />
            </CollapseSection>
          </Collapse>
        </div>
      </div>
    </>
  );
}
