import HomeIcon from '../../assets/icons/home.svg?react';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import { AbovePlayer } from '../../components/asset/AbovePlayer';

export default function QualityControlPage() {
  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Repository',
      link: '/repository'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{`Above Media | Repository `}</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8">
        <div className="grid grid-cols-7 p-5 gap-4">
          <div className="col-span-7 flex flex-row justify-between items-center">
            <div className="flex flex-row">
              <div className="font-semibold text-lg text-gray-900">Quality Control Page</div>
            </div>
          </div>
        </div>
        <div className="relative border-y border-gray-100">
          <div className="grid grid-cols-7">
            <div className="col-span-5 flex flex-row">
              <div className="py-4 px-8 grow">
                <AbovePlayer
                  dashManifestUrl={
                    'https://content.players.castlabs.com/demos/clear-segmented/manifest.mpd'
                  }
                  drmTodayToken={''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
