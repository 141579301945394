import { useAppSelector } from '../../app/hooks';
import { selectIngest } from '../../features/ingestion/ingestSlice';
import { highlightFile } from '../shared/Formatting';
import { audioLanguages, subtitleLanguages } from '../../common/languages';
import { Control, Controller, FormState, useWatch } from 'react-hook-form';
import Select from '../ui/Select';
import { AnalyzeFile } from '../../features/ingestion/types';
import Button from '../ui/Button';
import MinusIcon from '../../assets/icons/minus.svg?react';
import clsx from 'clsx';

export default function FileList(props: {
  control: Control<{ files: AnalyzeFile[]; editVersion: string; studio: string }, any>;
  formState: FormState<{ files: AnalyzeFile[]; editVersion: string; studio: string }>;
}) {
  const ingest = useAppSelector(selectIngest);
  const files = useWatch({ name: 'files', control: props.control });
  const subExtensions = ['.srt', '.scc', '.sub', '.ttml', '.rtf', '.xml'];

  return (
    <>
      <h3
        key="add new session"
        className="font-semibold bg-gray-50 border-t border-b border-gray-200 text-gray-700 px-4 py-2">
        Include files
      </h3>
      <div className="flex flex-col gap-4 divide-y divide-gray-100 w-full p-4">
        {ingest.detectedFiles.map((file, index) => (
          <div key={index} className="flex flex-row gap-4 w-full items-center pt-4">
            <Controller
              name={`files.${index}.location`}
              control={props.control}
              render={({ field }) => (
                <div
                  className={clsx('grow text-gray-900', {
                    'line-through text-gray-300':
                      files && files[index] && files[index].included === false
                  })}>
                  {highlightFile(file.location)}
                  <input type="hidden" onChange={field.onChange} />
                </div>
              )}
            />
            <Controller
              name={`files.${index}.language`}
              rules={{
                required: {
                  value:
                    !file.location.endsWith('.csv') &&
                    files &&
                    files[index] &&
                    files[index].included != false,
                  message: 'language is required!'
                }
              }}
              control={props.control}
              render={({ field }) => (
                <div className="flex-none">
                  <Select
                    isClearable={true}
                    isDisabled={
                      !files ||
                      (files && files[index] && files[index].included === false) ||
                      file.location.endsWith('.csv')
                    }
                    isInvalid={
                      props.formState.errors &&
                      props.formState.errors.files &&
                      (props.formState.errors.files as any)[index]?.language
                    }
                    className="w-full min-w-64"
                    options={
                      subExtensions.some((ext) => file.location.endsWith(ext))
                        ? subtitleLanguages
                        : audioLanguages
                    }
                    placeholder="Please select language..."
                    onChange={(option: any) =>
                      option && option.value ? field.onChange(option.value) : field.onChange('')
                    }
                    value={(subExtensions.some((ext) => file.location.endsWith(ext))
                      ? subtitleLanguages
                      : audioLanguages
                    ).filter((option) => field.value === option.value)}
                  />
                  {props.formState.errors.files && (
                    <div className="text-sm text-danger">
                      {(props.formState.errors.files as any)[index]?.language.message}
                    </div>
                  )}
                </div>
              )}
            />
            <Controller
              name={`files.${index}.included`}
              control={props.control}
              render={({ field }) => (
                <div>
                  <Button onClick={() => field.onChange(!field.value)} link>
                    <MinusIcon
                      className={clsx('', {
                        'stroke-gray': field.value || typeof field.value === 'undefined',
                        'stroke-gray-300': field.value === false
                      })}
                    />
                  </Button>
                </div>
              )}
            />
          </div>
        ))}
      </div>
    </>
  );
}
