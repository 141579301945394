import React, { forwardRef, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { cva, type VariantProps } from 'class-variance-authority';
import { Link } from 'react-router-dom';

const buttonStyle = cva(['flex flex-row gap-2 items-center justify-center transition'], {
  variants: {
    variant: {
      default: [
        'text-gray-800 bg-gray-50 hover:bg-gray-100 border border-gray-300 focus:shadow-btn active:shadow-btn'
      ],
      titleLabelArrow: [
        'gap-1 text-gray-800 bg-gray-50 hover:bg-gray-100 border border-gray-300 focus:shadow-btn active:shadow-btn'
      ],
      defaultOutlined: [
        'text-gray-800 bg-white hover:bg-gray-100 border border-gray-300 focus:shadow-btn active:shadow-btn'
      ],
      primary: [
        'text-white bg-primary-600 hover:bg-primary-700 focus:shadow-btn-primary active:shadow-btn-primary'
      ],
      primaryOutlined: [
        'text-primary hover:bg-primary-25 border border-primary bg-white',
        'focus:shadow-btn-primary active:shadow-btn-primary'
      ],
      danger: [
        'text-white bg-danger hover:bg-danger-700 focus:shadow-btn-danger active:shadow-btn-danger'
      ],
      dangerOutlined: [
        'text-danger-700 bg-white hover:bg-danger-50 border border-danger-300 focus:shadow-btn-danger active:shadow-btn-danger'
      ]
    },
    size: {
      xs: ['text-xs py-1 px-2'],
      sm: ['text-sm py-2 px-3.5'],
      md: ['text-md py-2.5 px-4'],
      lg: ['text-lg py-2.5 px-4.5'],
      xl: ['text-xl py-3 px-5'],
      '2xl': ['text-2xl py-4 px-7']
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'md'
  }
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonStyle> {
  block?: boolean;
  rounded?: boolean;
  link?: boolean;
  href?: string;
  prefixNode?: ReactNode;
  suffixNode?: ReactNode;
  target?: string;
  isRouterLink?: boolean;
}

const Button = forwardRef(
  (
    {
      className,
      variant,
      size,
      disabled,
      rounded,
      block,
      link,
      target,
      isRouterLink = false,
      ...props
    }: ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>,
    forwardRef: Ref<HTMLButtonElement & HTMLAnchorElement>
  ) => {
    const buttonClasses = clsx(
      {
        'cursor-pointer opacity-100': typeof disabled === 'undefined' || disabled === false,
        'cursor-not-allowed opacity-30': disabled,
        'rounded-lg': rounded,
        'w-full': block,
        'bg-transparent hover:bg-transparent border-0 hover:underline focus:shadow-0 active:shadow-0':
          link,
        'text-primary-700': variant === 'primary' && link,
        'text-danger-700': variant === 'danger' && link
      },
      className
    );
    if ((link || props.href) && isRouterLink === false) {
      return (
        <a
          href={props.href}
          target={target}
          className={twMerge(buttonStyle({ variant, size, className: buttonClasses }))}
          ref={forwardRef}
          {...props}>
          {props.prefixNode}
          {props.children}
          {props.suffixNode}
        </a>
      );
    }
    if ((link || props.href) && isRouterLink === true) {
      return (
        <Link
          to={props.href || ''}
          className={twMerge(buttonStyle({ variant, size, className: buttonClasses }))}
          ref={forwardRef}
          {...props}>
          {props.prefixNode}
          {props.children}
          {props.suffixNode}
        </Link>
      );
    }
    return (
      <button
        disabled={disabled}
        className={twMerge(buttonStyle({ variant, size, className: buttonClasses }))}
        {...props}
        ref={forwardRef}>
        {props.prefixNode}
        {props.children}
        {props.suffixNode}
      </button>
    );
  }
);
Button.displayName = 'Button';

export default Button;
