import * as queries from '../../graphql/queries/castlabs';
import { CastlabsPO, CastlabsPOItem } from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { WORKFLOW_API_URL } from '../../common/constants';
import { getAccessToken } from '../../common/auth';

export const workflowApi = createApi({
  reducerPath: 'workflowApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: WORKFLOW_API_URL,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      if (accessToken) headers.set('authorization', accessToken);
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getPO: builder.query<CastlabsPO, { poName: string; organization?: string }>({
      query: ({ organization, ...variables }) => ({
        url: '',
        method: 'POST',
        headers: {
          'x-castlabs-organization': organization || 'above'
        },
        body: JSON.stringify({ query: queries.getPO, variables: variables })
      }),
      transformResponse: (response: { data: { list_POs: { pos: CastlabsPO[] } } }) =>
        response?.data?.list_POs.pos[0]
    }),
    getPOItem: builder.query<
      CastlabsPOItem,
      { poName: string; poItemId: string; organization?: string }
    >({
      query: ({ organization, ...variables }) => ({
        url: '',
        method: 'POST',
        headers: {
          'x-castlabs-organization': organization || 'above'
        },
        body: JSON.stringify({ query: queries.getPOItem, variables: variables })
      }),
      transformResponse: (response: { data: { list_POs: { pos: CastlabsPO[] } } }) =>
        response?.data?.list_POs.pos[0].poitems[0]
    })
  })
});

export const { useGetPOQuery, useGetPOItemQuery } = workflowApi;
